export default function useDynamicThumbnail(
    originalImageUrl: string | undefined,
    width: number,
    height: number = 0
): string {
    let url = originalImageUrl;

    if (url === undefined || url === '') {
        return "/product_placeholder.webp";
    }

    if (height > 0) {
        if (url.indexOf('?') > -1) {
            url = url + `&height=${height}`;
        } else {
            url = url + `?height=${height}`;
        }
    }

    if (width > 0) {
        if (url.indexOf('?') > -1) {
            url = url + `&width=${width}`;
        } else {
            url = url + `?width=${width}`;
        }
    }

    return url;
}
